import Vue from 'vue';
import { formatFileSize } from '@/utils/formatFileSize';
import { apkValidators } from '@/utils/validators';
import { uploadNewApkVersion } from '@/api/apk';

export default Vue.extend({
  name: 'AddNewApkVersionModal',
  props: {
    isShow: Boolean,
    refetchApkVersions: Function,
  },

  data: () => ({
    isLoading: false,
    file: null,
    description: '',
    versionCode: '',
    versionName: '',
    emptyFileError: '',
    unsupportedFileError: '',
    rules: apkValidators,
  }),

  methods: {
    onAddApkFileClick() {
      this.$refs.fileInput.click();
    },

    handleFileUpload(e) {
      this.file = e.target.files[0];
      this.emptyFileError = '';

      if (this.file.name.split('.').pop() !== 'apk') {
        this.unsupportedFileError = 'Unsupported file type';
        return;
      }

      this.unsupportedFileError = '';
    },

    formatSize(size) {
      return formatFileSize(size);
    },

    onClearFile() {
      this.file = null;
      this.$refs.fileInput.value = '';
    },

    onKeyDown(event) {
      const allowedKeys = [
        'Delete',
        'Tab',
        'ArrowLeft',
        'ArrowRight',
        'Backspace',
        '.',
      ];

      const numberKey = Number(event.key);

      if (
        (numberKey >= 0 && numberKey <= 9) ||
        allowedKeys.includes(event.key)
      ) {
        if (event.key === '.') {
          const value = event.target.value;
          const dotCount = (value.match(/\./g) || []).length;

          if (dotCount >= 2) {
            event.preventDefault();
          }
        }

        return;
      }

      event.preventDefault();
    },

    async onSubmit() {
      const isValid = this.$refs.form.validate();

      if (!this.file) {
        this.emptyFileError = 'Upload apk file';
        return;
      }

      if (this.unsupportedFileError) return;

      if (!isValid) return;

      this.isLoading = true;

      const isUploaded = await uploadNewApkVersion({
        apk: this.file,
        versionCode: this.versionCode,
        versionName: this.versionName,
        description: this.description,
      });

      this.isLoading = false;

      if (isUploaded) {
        this.refetchApkVersions();
        this.$emit('close');

        this.$store.commit('showSnackbar', {
          message: 'Version added',
          options: { color: 'success' },
        });
      }
    },
  },
});
