import { mapActions } from 'vuex';

import {
  emailValidators,
  passwordValidators,
  accessCodeValidators,
} from '@/utils/validators.js';

export default {
  name: 'LoginForm',
  data: () => ({
    step: 1,
    email: '',
    password: '',
    accessCode: '',
    valid: false,
    emailRules: emailValidators,
    passwordRules: passwordValidators,
    accessCodeRules: accessCodeValidators,
    disabled: false,
  }),
  methods: {
    ...mapActions({
      login: 'auth/login',
      loginContinue: 'auth/loginContinue',
      logout: 'auth/loginContinue',
      forceLogout: 'auth/logout',
    }),
    async onLogin() {
      if (this.valid) {
        this.disabled = true;
        const responseData = await this.login({
          email: this.email,
          password: this.password,
        });

        if (responseData?.error?.response?.data?.error.code === 1487778503) {
          this.$store.dispatch('showSnackbar', {
            message: 'Error on the mail server side. Please try again later',
            options: { color: 'red' },
          });
        }

        if (responseData.success) this.step = 2;
        this.disabled = false;
      }
    },
    async onLoginContinue() {
      if (this.accessCode) {
        await this.loginContinue({
          email: this.email,
          key: this.accessCode,
        });

        this.$router.replace({ name: 'users' });
      }
    },
    preventSubmit(event) {
      event.preventDefault();
      this.onLoginContinue();
    },
  },
  created() {
    const key = this.$route.query?.key;
    if (key) {
      this.forceLogout(key).then(() => {
        this.$store.dispatch('showSnackbar', {
          message: 'Previous session successfully deleted',
          options: { color: 'green' },
        });
      });
      this.$router.replace({ name: 'login' });
    }
  },
};
