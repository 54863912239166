<template>
  <div :class="`d-flex flex-column ${className ?? ''}`">
    <div class="d-flex justify-space-between white--text mb-1 item-col__title">
      {{ title }}
    </div>
    <div
      class="item-col__description"
      v-for="(option, idx) in description.split('\n')"
      :key="idx"
    >
      {{ option }}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ItemCol',
  props: {
    title: String,
    description: String,
    className: String,
  },
};
</script>

<style>
.item-col__title {
  font-weight: 700;
  font-size: 17px;
  width: 138px;
}

.item-col__description {
  color: rgba(255, 255, 255, 0.72);
  font-size: 15px;
  max-width: 618px;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
</style>
